<template>
  <div class="position_relative">
    <e-auto-complete class="auto-input"
                     v-model="text"
                     :values="dynamicItems"
                     @update="daynamicSearchCstEnm"
                     :read-only="readOnly"
                     :disabled="disabled"
                     :is-filter="false"
                     @input="inputEvt"
    >
    </e-auto-complete>
  </div>
</template>

<script>
import apiCommons from '@/api/services/commons'
import EAutoComplete from '@/components/common/EAutoCompleteWithCode'

export default {
  name: 'EAutoCompleteCstEnm',
  components: {
    EAutoComplete
  },
  props: {
    ctrCd: { type: String, default: '', required: false },
    cdNm: { type: String, default: '', required: false },
    readOnly: { type: Boolean, default: false, required: false },
    cstCatCd: { type: String, default: '30', required: false },
    disabled: { type: Boolean, default: false, required: false },
    type: { type: String, default: '', required: false },
    idx: { type: String, default: '', required: false }
  },
  data () {
    return {
      selfYn: 'N',
      text: '',
      rtnVo: {},
      items: [],
      dynamicItems: [],
      preKeyowrd: '',
      intervalFunc: undefined
    }
  },
  watch: {
    cdNm () {
      if (this.text !== this.$props.cdNm) {
        this.text = this.$props.cdNm
      }
    }
  },
  created () {
    if (this.text !== this.$props.cdNm) {
      this.text = this.$props.cdNm
    }
  },
  methods: {
    daynamicSearchCstEnm (e) {
      const THIS = this
      const keyword = e

      const rtn = {
        cd: '',
        cdNm: keyword,
        isSelected: false,
        idx: this.idx,
        type: this.type
      }
      this.$emit('change', rtn)

      if (keyword === '' || keyword.length < 2) {
        this.items = []
        this.dynamicItems = []
        this.preKeyowrd = ''
        return
      }
      // backspace, del key로 단어 삭제시
      if (keyword !== this.preKeyowrd && this.preKeyowrd.indexOf(keyword) === 0) {
        this.preKeyowrd = ''
      // 붙여 넣기 등으로 글자 자체가 변경 되었을때
      } else if (this.preKeyowrd.indexOf(keyword) === -1 && this.preKeyowrd !== keyword) {
        this.preKeyowrd = ''
      }

      // console.log('keyword => ', keyword)

      if (this.intervalFunc !== undefined) {
        clearInterval(this.intervalFunc)
        this.intervalFunc = undefined
      }
      this.intervalFunc = setInterval(function () {
        apiCommons.getCommonsRealShipper(keyword, THIS.ctrCd, THIS.cstCatCd).then((data) => {
          // console.log('result => ', keyword)
          if (data.headers.respcode === 'C0000') {
            // keyword 가 짧을 수록 응답 시간이 오래 걸려 방어 로직
            if (keyword === THIS.preKeyowrd || THIS.preKeyowrd.indexOf(keyword) === 0) {
              return
            }
            // console.log('result apply => ', keyword)
            THIS.preKeyowrd = keyword
            THIS.items = data.data
            if (THIS.items === undefined) {
              THIS.dynamicItems = []
            } else {
              THIS.dynamicItems = THIS.items.map(vo => {
                return {
                  cd: vo.cstNo,
                  cdNm: vo.cstEnm,
                  displayText: vo.cstEnm
                }
              })
            }

            THIS.dynamicItems.push({
              cd: '',
              cdNm: keyword.toUpperCase(),
              displayText: `${THIS.$t('msg.CSBK100.050')} <span style='color:#d8d8d8;'>(${keyword.toUpperCase()})</span>`
            })
          }
        })
        clearInterval(THIS.intervalFunc)
        THIS.intervalFunc = undefined
      }, 300)
    },
    inputEvt (vo) {
      // this.$emit('objData', { idx: this.idx, type: this.type })

      const rtn = {
        cd: vo.cd,
        cdNm: vo.cdNm,
        isSelected: true,
        idx: this.idx,
        type: this.type
      }
      this.$emit('change', rtn)
    }
  }
}

</script>
